import { api } from "@utils/api";
import { useT } from "@utils/translation";
import { ReactNode, useCallback, useEffect, useState } from "react";
import { useAuth } from "./state";
import { datadogRum } from "@datadog/browser-rum";
import { DD_USER_PROPERTY } from "@utils/datadog";
import { toast } from "@components/toasts/state";
import Spinner from "@components/Spinner";
import { parseClientError } from "@utils/errors";

async function getToken(userName: string): Promise<string | undefined> {
  try {
    const { data } = await api.get<{ token: string }>("/user/login", {
      params: { userName },
    });

    if (data && data.token) {
      return data.token;
    }
  } catch (e) {
    parseClientError(e, "GET_TOKEN");
    throw e;
  }
}

export default function Auth({ children }: { children: ReactNode }) {
  const { t } = useT();
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const setAuthState = useAuth((s) => s.setAuthState);
  const token = useAuth((s) => s.token);
  const userName = useAuth((s) => s.userName);

  const getUser = useCallback(async () => {
    try {
      const { data } = await api.get("/user/getUser");

      const identification = data?.identifications?.[0];

      if (identification) {
        setAuthState({ userIdentification: identification.id });
        datadogRum.setUserProperty(
          DD_USER_PROPERTY.USER_IDENTIFICATION,
          identification
        );
      }
    } catch (error: any) {
      toast.error({ message: t("error.loginUnsuccessful") });
      parseClientError(error, "GET_USER");
    }
  }, [t, setAuthState]);

  const login = useCallback(async () => {
    let userNameToUse = userName;

    try {
      if (!userNameToUse) {
        userNameToUse = crypto.randomUUID();
        setAuthState({ userName: userNameToUse });
        datadogRum.setUser({ userName: userNameToUse });
      }

      const token = await getToken(userNameToUse);
      if (token) {
        setAuthState({ token });
      }
    } catch (error: any) {
      toast.error({ message: t("error.loginUnsuccessful") });
      parseClientError(error, "LOGIN");
    }
  }, [t, setAuthState, userName]);

  useEffect(() => {
    login();
  }, [login]);

  useEffect(() => {
    if (isAuthenticated) {
      getUser();
    }
  }, [getUser, isAuthenticated]);

  useEffect(() => {
    api.interceptors.response.clear();

    if (userName) {
      api.interceptors.response.use(undefined, async (error) => {
        if (error.response?.status !== 401) {
          return Promise.reject(error);
        }

        const token = await getToken(userName);
        if (token) {
          setAuthState({ token });
        }

        if (error.config) {
          error.config.headers.set("Authorization", token);
          return api.request(error.config);
        }
      });
    }
  }, [userName, setAuthState]);

  useEffect(() => {
    api.interceptors.request.clear();

    if (token) {
      api.interceptors.request.use((config) => {
        config.headers.set("Authorization", token);
        return config;
      });

      setIsAuthenticated(true);
    } else {
    }
  }, [token]);

  if (!isAuthenticated) {
    return (
      <div className="flex flex-col items-center pt-8">
        <Spinner />
      </div>
    );
  }

  return children;
}
